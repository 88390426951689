<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback /> {{ $t('Expense Summary') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="addPayload" />
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-select
            v-model="ExpanseStatusSelect"
            :items="ExpanseStatusList"
            :label="$t('status')"
            dense
            outlined
            :item-text="$i18n.locale"
            item-value="value"
            @change="addPayload(payload.start, payload.end)"
          ></v-select>
        </v-col>
      </v-row>
      <span class="font-weight-bold tetx-lg pl-2">
        {{ $t("summary_of_drug_costs") }}
      </span>
      <v-data-table
        :headers="columns1"
        :items="drug_costs.list"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.type`]="{ item }">
          <span :class="item.type == $t('total')?'font-weight-bold primary--text':''">
            {{ item.type }}
          </span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span :class="item.type == $t('total')?'font-weight-bold primary--text':''">
            {{ item.amount }}
          </span>
        </template>
      </v-data-table>
      <span class="font-weight-bold tetx-lg pl-2">
        {{ $t("summary_of_cost_of_equipment_used") }}
      </span>
      <v-data-table
        :headers="columns1"
        :items="cost_of_equipment_used.list"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.type`]="{ item }">
          <span :class="item.type == $t('total')?'font-weight-bold primary--text':''">
            {{ item.type }}
          </span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span :class="item.type == $t('total')?'font-weight-bold primary--text':''">
            {{ item.amount }}
          </span>
        </template>
      </v-data-table>
      <span class="font-weight-bold tetx-lg pl-2">
        {{ $t("summary_of_examination_fees") }}
      </span>
      <v-data-table
        :headers="columns2"
        :items="examination_fees.list"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.name`]="{ item }">
          <span :class="item.name == $t('total')?'font-weight-bold primary--text':''">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span :class="item.name == $t('total')?'font-weight-bold primary--text':''">
            {{ item.amount }}
          </span>
        </template>
      </v-data-table>
      <span class="font-weight-bold tetx-lg pl-2">
        {{ $t("summary_the_cost_of_a_doctor") }}
      </span>
      <v-data-table
        :headers="columns2"
        :items="cost_of_a_doctor.list"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.name`]="{ item }">
          <span :class="item.name == $t('total')?'font-weight-bold primary--text':''">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span :class="item.name == $t('total')?'font-weight-bold primary--text':''">
            {{ item.amount }}
          </span>
        </template>
      </v-data-table>
      <span class="font-weight-bold tetx-lg pl-2">
        {{ $t("summary_of_staff_costs") }}
      </span>
      <v-data-table
        :headers="columns2"
        :items="staff_costs.list"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.name`]="{ item }">
          <span :class="item.name == $t('total')?'font-weight-bold primary--text':''">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span :class="item.name == $t('total')?'font-weight-bold primary--text':''">
            {{ item.amount }}
          </span>
        </template>
      </v-data-table>
      <span class="font-weight-bold tetx-lg pl-2">
        {{ $t("summary_of_general_expenses") }}
      </span>
      <v-data-table
        :headers="columns3"
        :items="general_expenses"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.name`]="{ item }">
          <span :class="item.name == $t('total')||item.name == $t('total_amount')||item.name == `${$t('total')} ${$t('expenditure')}`?'font-weight-bold primary--text':''">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span :class="item.name == $t('total')||item.name == $t('total_amount')||item.name == `${$t('total')} ${$t('expenditure')}`?'font-weight-bold primary--text':''">
            {{ item.amount }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportPay, removeComma } from '../useExcel'
import Comeback from '../Comeback.vue'
import { sumdate } from '@/plugins/filters'
import ExpanseStatus from '@/@fake-db/data/ExpanseStatus.json'

export default {
  components: {
    DateFilters,
    Comeback,
  },
  setup() {
    const ExpanseStatusList = ref(ExpanseStatus.data)
    const ExpanseStatusSelect = ref('')
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const columns1 = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('list'), value: 'name' },
      { text: i18n.t('type'), value: 'type' },
      {
        text: i18n.t('cost_amount'), value: 'amount', align: 'end',
      },
    ])
    const columns2 = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('list'), value: 'name' },
      {
        text: i18n.t('cost_amount'), value: 'amount', align: 'end',
      },
    ])
    const columns3 = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('type'), value: 'groupname' },

      { text: i18n.t('list'), value: 'name' },
      {
        text: i18n.t('cost_amount'), value: 'amount', align: 'end',
      },
    ])
    const drug_costs = ref({})
    const cost_of_equipment_used = ref({})
    const examination_fees = ref({})
    const cost_of_a_doctor = ref({})
    const staff_costs = ref({})
    const general_expenses = ref([])
    const options = ref({})
    const exportLoading = ref(false)
    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        select_check_id: ExpanseStatusSelect.value,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }

    const mapList = (drug_costs_list, groupname) => {
      const data = drug_costs_list.map((item, i) => {
        i++

        return { number: i, ...item, groupname }
      })

      return data
    }

    const mapGroup = general_expenses => {
      general_expenses.group = general_expenses.group.map(item => {
        const m = mapList(item.list, item.groupname)

        return [
          // { number: item.groupname },
          ...m,
          {
            name: `${i18n.t('total')} ${i18n.t('expenditure')}`,
            amount: item.groupamount,
          },
        ]
      })
      const group = []
      general_expenses.group.forEach(item => {
        for (const sub_item of item) {
          group.push(sub_item)
        }
      })

      return group
    }

    const searchReport = async payload => {
      loading.value = true
      const { data, sum_total } = await reportPay(payload)
      const {
        drug_costs: drugCourseList,
        cost_of_equipment_used: cost_of_equipment_usedList,
        examination_fees: examination_feesList,
        cost_of_a_doctor: cost_of_a_doctorList,
        staff_costs: staff_costsList,
        general_expenses: general_expensesList,
      } = data

      /* drug_costs */
      if (drugCourseList.list) {
        drugCourseList.list = await mapList(drugCourseList.list)
        drugCourseList.list.push({
          type: i18n.t('total'),
          amount: drugCourseList.total,
        })
        drug_costs.value = drugCourseList
      } else {
        drug_costs.value.list = []
      }

      /* cost_of_equipment_used */
      if (cost_of_equipment_usedList.list) {
        cost_of_equipment_usedList.list = await mapList(
          cost_of_equipment_usedList.list,
        )
        cost_of_equipment_usedList.list.push({
          type: i18n.t('total'),
          amount: cost_of_equipment_usedList.total,
        })
        cost_of_equipment_used.value = cost_of_equipment_usedList
      } else {
        cost_of_equipment_used.value.list = []
      }

      /* examination_fees */
      if (examination_feesList.list) {
        examination_feesList.list = await mapList(examination_feesList.list)
        examination_feesList.list.push({
          name: i18n.t('total'),
          amount: examination_feesList.total,
        })
        examination_fees.value = examination_feesList
      } else {
        examination_fees.value.list = []
      }

      /* cost_of_a_doctor */
      if (cost_of_a_doctorList.list) {
        cost_of_a_doctorList.list = await mapList(cost_of_a_doctorList.list)
        cost_of_a_doctorList.list.push({
          name: i18n.t('total'),
          amount: cost_of_a_doctorList.total,
        })
        cost_of_a_doctor.value = cost_of_a_doctorList
      } else {
        cost_of_a_doctor.value.list = []
      }

      /* staff_costs */
      if (staff_costsList.list) {
        staff_costsList.list = await mapList(staff_costsList.list)
        staff_costsList.list.push({
          name: i18n.t('total'),
          amount: staff_costsList.total,
        })
        staff_costs.value = staff_costsList
      } else {
        staff_costs.value.list = []
      }

      /* general_expenses */
      if (general_expensesList.group) {
        const a = await mapGroup(general_expensesList)
        a.push({ name: i18n.t('total'), amount: general_expensesList.total })
        a.push({
          name: i18n.t('total_amount'),
          amount: sum_total,
        })
        general_expenses.value = a
      } else {
        general_expenses.value = []
      }
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value

      if (!drug_costs.value.list) {
        drug_costs.value.list = []
      }
      if (!cost_of_equipment_used.value.list) {
        cost_of_equipment_used.value.list = []
      }
      if (!examination_fees.value.list) {
        examination_fees.value.list = []
      }
      if (!cost_of_a_doctor.value.list) {
        cost_of_a_doctor.value.list = []
      }
      if (!staff_costs.value.list) {
        staff_costs.value.list = []
      }
      if (!general_expenses.value) {
        general_expenses.value = []
      }

      const fileName = `${i18n.t('ExpenseSummary')} ${i18n.t(
        'since',
      )} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const titleTable = {
        number: '#',
        name: `${i18n.t('list')}`,
        type: `${i18n.t('type')}`,
        amount: `${i18n.t('amount')}`,
      }
      const Heading = [
        [
          `${i18n.t('ExpenseSummary')} ${i18n.t('since')} ${sumdate(
            start,
          )} ${i18n.t('to')} ${sumdate(end)} `,
        ],
      ]

      const data_general_expenses = general_expenses.value.map(item => {
        item.type = item.groupname
        delete item.groupname

        return item
      })
      let dataExport = [
        {},
        { number: i18n.t('summary_of_drug_costs') },
        titleTable,
        ...drug_costs.value.list,
        {},
        { number: i18n.t('summary_of_cost_of_equipment_used') },
        titleTable,
        ...cost_of_equipment_used.value.list,

        {},
        { number: i18n.t('summary_of_examination_fees') },
        titleTable,
        ...examination_fees.value.list,

        {},
        { number: i18n.t('summary_the_cost_of_a_doctor') },
        titleTable,
        ...cost_of_a_doctor.value.list,
        {},
        { number: i18n.t('summary_of_staff_costs') },
        titleTable,
        ...staff_costs.value.list,
        {},
        { number: i18n.t('summary_of_general_expenses') },
        titleTable, ...data_general_expenses,
      ]
      dataExport = await removeComma(
        JSON.parse(JSON.stringify(dataExport)),
      )
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: ['number', 'name', 'type', 'amount'],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }]
      const wscols = [{ wch: 5 }, { wch: 25 }, { wch: 12 }, { wch: 12 }]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('ExpenseSummary')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      ExpanseStatusList,
      ExpanseStatusSelect,
      payload,
      loading,
      columns1,
      drug_costs,
      cost_of_equipment_used,
      examination_fees,
      cost_of_a_doctor,
      staff_costs,
      general_expenses,
      options,
      exportLoading,
      exportExcel,
      columns2,
      columns3,
      addPayload,
      mdiFileExcelOutline,
    }
  },
}
</script>
