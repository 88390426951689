<template>
  <v-row class='px-2'>
    <v-col
      cols='12'
      md='6'
      lg='3'
      class='py-0'
    >
      <v-select
        v-model='select_range'
        :items='data_range_select'
        :label="$t('select_range')"
        :item-text='$i18n.locale'
        item-value='value'
        outlined
        dense
      ></v-select>
    </v-col>
    <v-col
      cols='12'
      md='6'
      lg='3'
      class='py-0'
    >
      <v-select
        v-model='optionsSelected'
        :items='select_range==2?dataMonthList:select_range==3?dataYearList:[]'
        :label="select_range==1?$t('daily'):select_range==2?$t('choose_month'):select_range==3?$t('yearSelect'):select_range==4?$t('custom'):$t('all')"
        :disabled='select_range==1||select_range==4||select_range==5'
        outlined
        dense
        item-value='value'
        :item-text='$i18n.locale'
      ></v-select>
    </v-col>
    <v-col
      cols='12'
      md='6'
      class='py-0'
      lg='3'
    >
      <v-dialog
        ref='dateStart'
        v-model.trim='isShowDate1'
        :return-value.sync='date_start'
        width='290px'
      >
        <template v-slot:activator='{ on, attrs }'>
          <v-text-field
            v-model.trim='date_start'
            :label="$t('date')"
            outlined
            dense
            :disabled='select_range!=4 && select_range!=1'
            readonly
            v-bind='attrs'
            v-on='on'
          >
          </v-text-field>
        </template>
        <v-date-picker
          v-model.trim='date_start'
          :locale='$i18n.locale'
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color='primary'
            @click='$refs.dateStart.save(date_start)'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            text
            color='secondary'
            @click='isShowDate1 = false'
          >
            {{ $t('cancel') }}
          </v-btn>

          <v-spacer></v-spacer>
        </v-date-picker>
      </v-dialog>
    </v-col>
    <v-col
      cols='12'
      md='6'
      lg='3'
      class='py-0'
    >
      <v-dialog
        ref='dateEnd'
        v-model.trim='isShowDate2'
        :return-value.sync='date_end'
        width='290px'
      >
        <template v-slot:activator='{ on, attrs }'>
          <v-text-field
            v-model.trim='date_end'
            :label="$t('to')"
            readonly
            outlined
            dense
            :disabled='select_range!=4'
            v-bind='attrs'
            v-on='on'
          >
          </v-text-field>
        </template>
        <v-date-picker
          v-model.trim='date_end'
          :locale='$i18n.locale'
          scrollable
        >
          <v-spacer></v-spacer>

          <v-btn
            text
            color='primary'
            @click='$refs.dateEnd.save(date_end)'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            text
            color='secondary'
            @click='isShowDate2 = false'
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import monthList from '@/@fake-db/data/monthList.json'
import rangeSelete from '@/@fake-db/data/rangeSelete.json'
import shopStart from '@/api/shopStart'

export default {
  props: {
    defaultStatus: {
      type: Number,
      default: 2,
    },
  },
  setup(props, { emit }) {
    const moment = require('moment')
    const data_range_select = ref(rangeSelete.data)
    const select_range = ref(props.defaultStatus)
    const dataMonthList = ref(monthList.data)
    const optionsSelected = ref('')
    const date_start = ref(moment(new Date()).format('YYYY-MM-DD'))
    const date_end = ref(moment(new Date()).format('YYYY-MM-DD'))
    const isShowDate1 = ref(false)
    const isShowDate2 = ref(false)
    const dataYearList = ref([])
    const dateStartShop = ref('')

    const { shopStartGet } = shopStart
    shopStartGet().then(res => {
      dateStartShop.value = res
      const dt = new Date()
      const month = dt.getMonth()
      let createShopYear = +res.substring(4, 0) - 2
      const currentYear = new Date().getFullYear()
      for (let i = createShopYear; i <= currentYear; i++) {
        dataYearList.value.push({
          en: createShopYear,
          th: createShopYear + 543,
          value: createShopYear,
        })
        createShopYear += 1
      }
      optionsSelected.value = monthList.data[month].value
      starterFunction(props.defaultStatus)
    })

    const starterFunction = newVal => {
      const dt = new Date()
      const month = dt.getMonth()
      if (newVal == 1) {
        optionsSelected.value = ''
        date_start.value = moment(new Date()).format('YYYY-MM-DD')
        date_end.value = ''
      } else if (newVal == 2) {
        optionsSelected.value = monthList.data[month].value
        date_start.value = `${dataYearList.value[dataYearList.value.length - 1].value}-${optionsSelected.value}-01`
        date_end.value = moment(new Date()).format('YYYY-MM-DD')
      } else if (newVal == 3) {
        optionsSelected.value = dataYearList.value[dataYearList.value.length - 1].value
        date_start.value = `${optionsSelected.value}-01-01`
        date_end.value = moment(new Date()).format('YYYY-MM-DD')
      } else if (newVal == 4) {
        optionsSelected.value = ''
        date_start.value = moment(new Date()).format('YYYY-MM-DD')
        date_end.value = moment(new Date()).format('YYYY-MM-DD')
      } else if (newVal == 5) {
        optionsSelected.value = ''

        const fakeDate = `${dateStartShop.value.substring(4, 0) - 2}-01-01`
        date_start.value = fakeDate

        date_end.value = moment(new Date()).format('YYYY-MM-DD')
      }
    }

    const secondFunction = newVal => {
      if (select_range.value == 2) {
        const daysInMonth = new Date(dataYearList.value[dataYearList.value.length - 1].value, optionsSelected.value, 0).getDate()
        date_start.value = `${dataYearList.value[dataYearList.value.length - 1].value}-${optionsSelected.value}-01`
        date_end.value = `${dataYearList.value[dataYearList.value.length - 1].value}-${optionsSelected.value}-${daysInMonth}`
        sendDate()
      }
      if (select_range.value == 3) {
        const currentYear = new Date().getFullYear()
        date_start.value = `${newVal}-01-01`
        date_end.value = currentYear == optionsSelected.value ? moment(new Date()).format('YYYY-MM-DD') : `${optionsSelected.value}-12-31`
        sendDate()
      }
    }

    const sendDate = () => {
      emit('onSendDate', date_start.value, date_end.value)
    }

    watch(() => props.defaultStatus, () => {
      select_range.value = props.defaultStatus
    })

    watch([select_range, optionsSelected, date_start, date_end], (newVal, oldVal) => {
      if (newVal[0] != oldVal[0]) {
        starterFunction(newVal[0])
      }
      if (newVal[1] != oldVal[1]) {
        secondFunction(newVal[1])
      }
      if (newVal[2] != oldVal[2]) {
        if (select_range.value == 1) {
          date_end.value = date_start.value
          sendDate()
        }
        if (select_range.value == 4 || select_range.value == 5) {
          sendDate()
        }
      }
      if (select_range.value === 4 && newVal[2] !== oldVal[2]) {
        date_end.value = date_start.value
      }

      if (newVal[3] != oldVal[3]) {
        if (select_range.value == 4) {
          sendDate()
        }
      }
    })

    return {
      dataYearList,
      dataMonthList,
      select_range,
      data_range_select,
      optionsSelected,
      date_start,
      date_end,
      isShowDate1,
      isShowDate2,
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
