<template>
  <router-link
    class="font-weight-medium text-decoration-none me-2"
    :to="{ name: 'reports'}"
  >
    <v-tooltip
      color="#212121"
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          icon
          v-on="on"
        >
          <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("back") }}</span>
    </v-tooltip>
  </router-link>
</template>

<script>
import { mdiArrowLeftBold } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiArrowLeftBold,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
