import { api } from '@/api/main'
import { api as main_node } from '@/api/mainNode'
import { i18n } from '@/plugins/i18n'
import { checkStatusPo } from '@/plugins/filters'

const moment = require('moment')

// i18n({ locale: "en" })
function sumdate(string, status) {
  if (string != null) {
    const year = string.substring(0, 4)
    const sumYear = parseInt(year) + 543
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const months_th_mini =
      status === 'UpPer'
        ? [
            i18n.t('january'),
            i18n.t('february'),
            i18n.t('march'),
            i18n.t('april'),
            i18n.t('may'),
            i18n.t('june'),
            i18n.t('july'),
            i18n.t('august'),
            i18n.t('september'),
            i18n.t('october'),
            i18n.t('november'),
            i18n.t('december'),
          ]
        : [
            i18n.t('jan'),
            i18n.t('feb'),
            i18n.t('mar'),
            i18n.t('apr'),
            i18n.t('may'),
            i18n.t('jun'),
            i18n.t('jul'),
            i18n.t('aug'),
            i18n.t('sep'),
            i18n.t('oct'),
            i18n.t('nov'),
            i18n.t('dec'),
          ]
    const date = `${day} ${months_th_mini[mount - 1]} ${sumYear}`

    return date
  }

  return string
}

function sumdatetime(string, lang) {
  if (i18n.locale === 'en') {
    return sumdatetimeen(string)
  }
  if (string && string !== '-') {
    const year = string.substring(0, 4)
    const sumYear = parseInt(year) + 543
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const time = string.substring(11, 16)
    const months_th_mini = [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ]
    const date = `${day} ${months_th_mini[mount - 1]} ${sumYear}  ${time}`

    return date
  }

  return string
}

function sumdatetimeen(string) {
  if (string && string != '-') {
    const year = string.substring(0, 4)

    // let sumYear = parseInt(year) + 543;
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const time = string.substring(11, 16)
    const months_th_mini = [
      'Jan.',
      'Feb.',
      'Mar.',
      'Apr.',
      'May',
      'Jun.',
      'Jul.',
      'Aug.',
      'Sep.',
      'Oct.',
      'Nov.',
      'Dec.',
    ]
    const date = `${day} ${months_th_mini[mount - 1]} ${year}  ${time}`

    return date
  }

  return string
}

// SERVICE DATE

// สรุป กำไร-ขาดทุน
async function reportProfit(payload) {
  try {
    let arrFetchData = []
    const json_not_fount = {
      user_name: i18n.t('no_information'),
      type: i18n.t('expenditure'),
      amount: 0,
    }
    const { start = '2023-01-01', end = '2023-01-01' } = payload
    const body = {
      start,
      end,
    }
    const { data } = await main_node.post({
      path: 'reportprofit',
      body: { ...body, lang: i18n.locale },
    })
    arrFetchData = [
      {
        number: '1',
        list: i18n.t('summary_of_all_sales'),
        type: i18n.t('income'),
        amount: data[0].income_amount,
      },
      {
        number: '2',
        list: i18n.t('summary_of_drug_cost_equipment_used'),
      },
      {
        list: i18n.t('drug'),
        type: i18n.t('expenditure'),
        amount: data[0].drug_amount,
      },
      {
        list: i18n.t('equipment'),
        type: i18n.t('expenditure'),
        amount: data[0].equipment_amount,
      },
      {
        number: '3',
        list: i18n.t('total_costs'),
      },
      {
        list: `${i18n.t('doctor_fee')}(${i18n.t('examination_fee')})`,
      },
    ]
    if (data[0].examination_fee) {
      data[0].examination_fee.forEach(item => {
        arrFetchData.push({
          user_name: item.name,
          type: i18n.t('expenditure'),
          amount: item.amount,
        })
      })
    } else {
      arrFetchData.push(json_not_fount)
    }
    arrFetchData.push({
      list: i18n.t('doctor_fee'),
    })
    if (data[0].doctor_fee) {
      data[0].doctor_fee.forEach(item => {
        arrFetchData.push({
          user_name: item.name,
          type: i18n.t('expenditure'),
          amount: item.amount,
        })
      })
    } else {
      arrFetchData.push(json_not_fount)
    }
    arrFetchData.push({
      list: i18n.t('staff_costs'),
    })
    if (data[0].staff_costs) {
      data[0].staff_costs.forEach(item => {
        arrFetchData.push({
          user_name: item.name,
          type: i18n.t('expenditure'),
          amount: item.amount,
        })
      })
    } else {
      arrFetchData.push(json_not_fount)
    }
    arrFetchData.push({
      list: i18n.t('commission'),
    })
    if (data[0].commission) {
      data[0].commission.forEach(item => {
        arrFetchData.push({
          user_name: item.name,
          type: i18n.t('expenditure'),
          amount: item.amount,
        })
      })
    } else {
      arrFetchData.push(json_not_fount)
    }

    arrFetchData.push({
      number: '4',
      list: i18n.t('summary_of_general_expenses'),
    })

    if (data[0].summary_of_general) {
      data[0].summary_of_general.forEach(item => {
        arrFetchData.push({
          list: item.shop_bankgroup,
        })
        if (item.shop_bankpay) {
          item.shop_bankpay.forEach(sub_item => {
            arrFetchData.push({
              user_name: sub_item.name,
              type: i18n.t('expenditure'),
              amount: sub_item.amount,
            })
          })
        } else {
          arrFetchData.push(json_not_fount)
        }
      })
    } else {
      arrFetchData.push(json_not_fount)
    }

    arrFetchData.push({
      type: i18n.t('income'),
      amount: data[0].income_total ? data[0].income_total : 0,
    })
    arrFetchData.push({
      type: i18n.t('expenditure'),
      amount: data[0].expenditure_total ? data[0].expenditure_total : 0,
    })
    arrFetchData.push({
      type: i18n.t('net_profit'),
      amount: data[0].net_profit ? data[0].net_profit : 0,
    })

    return arrFetchData
  } catch (err) {
    console.log(err)
  }
}

// สรุปยอดใบเสร็จ
async function reportSummary(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    const {
      data,
      sum_order_total,
      sum_orderpay_all,
      shopbank,
      accrued_account,
      deposit,
      total_discount,
      wait_for_payment,
      total_amount,
    } = await main_node.post({
      path: 'reportsummaryOld',
      body: { ...body, lang: i18n.locale },
    })

    arrFetchData = data.map((item, i) => {
      item.user_fullname = `${item.user_fullname ? item.user_fullname : ''}`

      return { number: i + 1, ...item }
    })

    arrFetchData.push({
      point: `${i18n.t('total')}`,
      order_total: sum_order_total,
      discount: total_discount,
      orderpay_all: sum_orderpay_all,
    })

    for (const item of shopbank) {
      if (+item.shop_bank_total.split(',').join('') > 0) {
        let m = 0
        arrFetchData.push({ discount: item.shop_bank })
        for (const sub_item of item.shop_bank_detail) {
          if (sub_item.shop_bank_price != '0.00') {
            arrFetchData.push({
              discount: sub_item.shop_bank_name,
              orderpay_all: sub_item.shop_bank_price,
            })
          }
          if (m == item.shop_bank_detail.length - 1) {
            arrFetchData.push({
              discount: `${i18n.t('total')}`,
              orderpay_all: item.shop_bank_total,
            })
          }
          m++
        }
      }
    }

    arrFetchData.push({
      discount: i18n.t('accrued_account'),
      orderpay_all: accrued_account,
    })
    arrFetchData.push({
      discount: i18n.t('deposit'),
      orderpay_all: deposit,
    })
    arrFetchData.push({
      discount: i18n.t('wait_for_payment'),
      orderpay_all: wait_for_payment,
    })
    arrFetchData.push({
      discount: i18n.t('total_amount'),
      orderpay_all: total_amount,
    })

    return arrFetchData
  } catch (err) {
    console.log(err)
  }
}
async function reportSummaryNew(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    const {
      data,
      sum_order_total,
      sum_orderpay_all,
      shopbank,
      accrued_account,
      deposit,
      total_discount,
      wait_for_payment,
      total_amount,
    } = await main_node.post({
      path: 'reportsummary',
      body: { ...body, lang: i18n.locale },
    })

    arrFetchData = data.map((item, i) => {
      item.user_fullname = `${item.user_fullname ? item.user_fullname : ''}`
      return { ...item }
    })

    arrFetchData.push({
      point: `${i18n.t('total')}`,
      order_total: sum_order_total,
      discount: total_discount,
      orderpay_all: sum_orderpay_all,
    })

    for (const item of shopbank) {
      if (+item.shop_bank_total.split(',').join('') > 0) {
        let m = 0
        arrFetchData.push({ discount: item.shop_bank })
        for (const sub_item of item.shop_bank_detail) {
          if (sub_item.shop_bank_price != '0.00') {
            arrFetchData.push({
              discount: sub_item.shop_bank_name,
              orderpay_all: sub_item.shop_bank_price,
            })
          }
          if (m == item.shop_bank_detail.length - 1) {
            arrFetchData.push({
              discount: `${i18n.t('total')}`,
              orderpay_all: item.shop_bank_total,
            })
          }
          m++
        }
      }
    }

    arrFetchData.push({
      discount: i18n.t('accrued_account'),
      orderpay_all: accrued_account,
    })
    arrFetchData.push({
      discount: i18n.t('deposit'),
      orderpay_all: deposit,
    })
    arrFetchData.push({
      discount: i18n.t('wait_for_payment'),
      orderpay_all: wait_for_payment,
    })
    arrFetchData.push({
      discount: i18n.t('total_amount'),
      orderpay_all: total_amount,
    })

    return arrFetchData
  } catch (err) {
    console.log(err)
  }
}


// สรุปยอดขายยา/อุปกรณ์
async function reportDrug(payload) {
  try {
    let arrFetchData = []
    let arrFetchCustomer = []
    let {} = payload
    const body = payload

    const { data, total_price, total_discount, total_amount, customerData, total_unit } = await main_node.post({
      path: 'reportdrug',
      body: { ...body, lang: i18n.locale },
    })
    arrFetchData = data.map((item, i) => {
      // const date = item.date ? sumdatetime(item.date) : ''
      return { number: i + 1, ...item }
    })
    arrFetchData.push({
      druglot_name: '',
      name: `${i18n.t('total')}`,
      amount: total_unit,
      price: total_price,
      discount: total_discount,
      total: total_amount,
    })

    return { data: arrFetchData, customerData: customerData }
  } catch (err) {
    console.log(err)
  }
}

// สรุปยอดขายคอร์ส
async function reportcourse(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    const { data, datadetail } = await main_node.post({
      path: 'reportcourse',
      body: { ...body, lang: i18n.locale },
    })
    if (data.list) {
      arrFetchData = data.list.map((item, i) => ({ number: i + 1, ...item }))
      arrFetchData.push({
        order_create: `${i18n.t('total')}`,
        orderdetail_price: data.price,
        orderdetail_discount: data.discount,
        orderdetail_total: data.total,
      })
    }
    let dataDetail = []
    if (datadetail.customer_list) {
      dataDetail = datadetail.customer_list.map((item, i) => ({
        number: i + 1,
        ...item,
        customer_tags: `${item.customer_tags ? item.customer_tags : ''}`,
      }))
      dataDetail.push({
        customer_tags: `${i18n.t('total')}`,
        orderdetail_price: datadetail.price,
        orderdetail_discount: datadetail.discount,
        orderdetail_total: datadetail.total,
      })
    }

    return { data: arrFetchData, datadetail: dataDetail }
  } catch (err) {
    console.log(err)
  }
}

// รายงานวิเคราะห์รายการตรวจ
async function reportCheckAnalysis(payload) {
  try {
    let arrFetchData = []
    const body = payload
    let dataDetail = []
    const {
      data,
      total_checking_price,
      total_customer_old,
      total_customer_old_price,
      total_customer_new,
      total_customer_new_price,
      total_checking_count,
      datadetail,
    } = await main_node.post({
      path: 'reportcheckanalysis',
      body: { ...body, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => ({ number: i + 1, ...item }))
      arrFetchData.push({
        checking_name: `${i18n.t('total')}`,
        checking_price: total_checking_price,
        customer_old: total_customer_old,
        customer_old_price: total_customer_old_price,
        customer_new: total_customer_new,
        customer_new_price: total_customer_new_price,
        checking_count: total_checking_count,
      })
    }

    if (datadetail) {
      dataDetail = datadetail.map(item => ({
        ...item,
        customer_tags: `${item.customer_tags ? item.customer_tags : ''}`,
      }))
    }

    return { data: arrFetchData, dataDetail }
  } catch (err) {
    console.log(err)
  }
}

// รายงานวิเคราะห์ยอดขายคอร์ส
async function reportSalesAnalysis(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    let dataDetail = []
    const {
      data,
      total_course_price,
      total_customer_old,
      total_course_old_price,
      total_customer_new,
      total_customer_new_price,
      total_course_count,
      datadetail,
    } = await main_node.post({
      path: 'reportsalesanalysis',
      body: { ...body, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => ({ number: i + 1, ...item }))
      arrFetchData.push({
        course_name: `${i18n.t('total')}`,
        course_price: total_course_price,
        customer_old: total_customer_old,
        customer_old_price: total_course_old_price,
        customer_new: total_customer_new,
        customer_new_price: total_customer_new_price,
        course_count: total_course_count,
      })
    }

    if (datadetail) {
      dataDetail = datadetail.map((item, i) => ({
        ...item,
        customer_tags: `${item.customer_tags ? item.customer_tags : ''}`,
      }))
    }

    return { data: arrFetchData, dataDetail }
  } catch (err) {
    console.log(err)
  }
}

async function reportDrugAnalysis(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    let dataDetail = []
    const {
      data,
      total_drug_price,
      total_customer_old,
      total_customer_old_price,
      total_customer_new,
      total_customer_new_price,
      total_drug_count,
      datadetail,
    } = await main_node.post({
      path: 'reportdruganalysis',
      body: { ...body, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => ({ number: i + 1, ...item }))
      arrFetchData.push({
        drug_name: `${i18n.t('total')}`,
        drug_price: total_drug_price,
        customer_old: total_customer_old,
        customer_old_price: total_customer_old_price,
        customer_new: total_customer_new,
        customer_new_price: total_customer_new_price,
        drug_count: total_drug_count,
      })
    }

    if (datadetail) {
      dataDetail = datadetail.map((item, i) => ({
        ...item,
        customer_tags: `${item.customer_tags ? item.customer_tags : ''}`,
      }))
    }

    return { data: arrFetchData, dataDetail }
  } catch (err) {
    console.log(err)
  }
}

async function reportDisPensing(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload

    // let dataDetail = [] ;
    let {
      data,
      datadetail: dataDetail,
      sumtotal,
      total_unit,
    } = await main_node.post({
      path: 'reportdispensing',
      body: { ...body, lang: i18n.locale },
    })
    arrFetchData = data.map((item, i) => {
      // item.date = sumdatetime(item.order_create)
      delete item.order_create
      return { number: i + 1, ...item }
    })

    arrFetchData.push({ transfer_out: `${i18n.t('total')}`, sum_out: total_unit, total: sumtotal })
    if (dataDetail) {
      dataDetail = dataDetail.map((item, i) => {
        const druglot_name = `${item.druglot_name} (${sumdate(item.druglot_date)})`
        delete item.druglot_date

        return {
          number: i + 1,
          ...item,
          druglot_name,
          customer_idcard: item.customer_idcard ? item.customer_idcard : '',
          customer_birthdate: `${sumdate(item.customer_birthdate, 'UpPer')}`,
          customer_tags: `${item.customer_tags ? item.customer_tags : ''}`,
        }
      })
    } else {
      dataDetail = []
    }

    return { data: arrFetchData, dataDetail }
  } catch (err) {
    console.log(err)
  }
}

// สรุปรอบสต๊อกยา/อุปกรณ์
async function reportLotDrug(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload

    // let dataDetail = [] ;
    const { data, sum_cost, sum_income, sum_profit } = await main_node.post({
      path: 'reportlotdrug',
      body: { ...body, lang: i18n.locale },
    })
    arrFetchData = data.map((item, i) => {
      const druglot_name = `${item.druglot_name} (${sumdate(item.druglot_date)})`
      delete item.druglot_date

      // delete item.drug_id_pri;
      return { number: i + 1, ...item, druglot_name }
    })

    arrFetchData.push({
      drugordert_total: `${i18n.t('total')}`,
      cost: sum_cost,
      income: sum_income,
      profit: sum_profit,
    })

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// สรุปสต๊อกยา/อุปกรณ์คงเหลือ
async function reportDrugBalance(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload

    // let dataDetail = [] ;
    const { data, sum_cost, sum_income, sum_profit } = await api.post({
      path: 'reportdrugbalance',
      body: { ...body, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        item.druglot_amount = `${sumdate(item.druglot_date)} (${item.druglot_name})`
        return { number: i + 1, ...item }
      })
      arrFetchData.push({
        drugordert_total: `${i18n.t('total')}`,
        cost: sum_cost,
        income: sum_income,
        profit: sum_profit,
      })
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// สรุปความเคลื่อนไหวยา/อุปกรณ์
async function reportMovementDrug(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload

    const { data } = await api.post({
      path: 'reportmovementdrug',
      body: { ...body, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        let results = [{ number: i + 1, drug_id: item.drug_id, drug_name: item.drug_name }]
        results = item.detail.map(subItem => {
          results.push({
            druglot_name: subItem.druglot_name,
            date: subItem.date,
            code: subItem.code,
            type_name: subItem.type_name,
          })
        })

        return results
      })
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// สรุปความเคลื่อนไหวยา/อุปกรณ์
async function reportMovementDrug2(payload) {
  try {
    const arrFetchData = []
    let {} = payload
    const body = payload
    const { data, count, count_of_page, segment } = await api.post({
      path: 'reportmovementdrug',
      body: { ...body, lang: i18n.locale },
    })
    let i = 0
    for (const item of data) {
      arrFetchData.push({
        number: i + 1,
        drug_id: item.list.drug_id,
        drug_name: item.list.drug_name,
      })
      if (item.detail) {
        for (const item_detail of item.detail) {
          arrFetchData.push({
            druglot_name: `${item_detail.druglot_name}(${sumdate(item_detail.druglot_date).replaceAll(' ', '-')})`,
            date: item_detail.date,
            code: item_detail.code,
            type_name: item_detail.type_name,
            in_cost: item_detail.in_cost,
            in_price: item_detail.in_price,
            in_amount: item_detail.in_amount,
            in_cost_sum: item_detail.in_cost_sum,
            out_tranfer: item_detail.out_tranfer,
            out_tranfer_sum: item_detail.out_tranfer_sum,
            out_amount: item_detail.out_amount,
            out_amount_sum: item_detail.out_amount_sum,
            remove_count: item_detail.remove_count,
            balance_in: item_detail.balance_in,
            balance_in_cost_sum: item_detail.balance_in_cost_sum,
            balance_amount: item_detail.balance_amount,
            balance_cost_sum: item_detail.balance_cost_sum,
          })
        }
      }

      // อาจะติด Call black Function
      arrFetchData.push({
        number: '',
        drug_id: item.list.drug_id,
        drug_name: item.list.drug_name,
        in_cost: item.list.in_cost,
        in_price: item.list.in_price,
        in_amount: item.list.in_amount,
        in_cost_sum: item.list.in_cost_sum,
        out_tranfer: item.list.out_tranfer,
        out_tranfer_sum: item.list.out_tranfer_sum,
        out_amount: item.list.out_amount,
        out_amount_sum: item.list.out_amount_sum,
        remove_count: item.list.remove_count,
        balance_in: item.list.balance_in,
        balance_in_cost_sum: item.list.balance_in_cost_sum,
        balance_amount: item.list.balance_amount,
        balance_cost_sum: item.list.balance_cost_sum,
      })
      i++
    }

    return { data: arrFetchData, count, count_of_page, segment }
  } catch (err) {
    console.log(err)
  }
}

// รายงานยา/อุปกรณ์ ใกล้หมด
async function reportDrugLowest(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    const { data } = await main_node.post({
      path: 'reportdruglowest',
      body: { ...body, lang: i18n.locale },
    })
    arrFetchData = data.map((item, i) => ({ number: i + 1, ...item }))

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// รายงานยา/อุปกรณ์ หมดอายุ
async function reportExpired(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    const { data } = await main_node.post({
      path: 'reportexpired',
      body: { ...body, lang: i18n.locale },
    })
    let drugordert_total = 0
    arrFetchData = data.map((item, i) => {
      const druglot_name = `${item.druglot_name} ${sumdate(item.druglot_date)}`
      const drugorder_expdate = sumdate(item.drugorder_expdate)
      drugordert_total += item.drugordert_total ? +item.drugordert_total.replace(/,/g, '') : 0
      delete item.druglot_date

      return {
        number: i + 1,
        ...item,
        druglot_name,
        drugorder_expdate,
      }
    })
    arrFetchData.push({
      drugorder_expdate: i18n.t('total'),
      drugordert_total: drugordert_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    })

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// รายงานโอนยา/อุปกรณ์
async function reportTransfer(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    const { data } = await main_node.post({
      path: 'reporttransfer',
      body: { ...body, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        const shop_name = `${item.shop_name} (${item.shop_id})`
        delete item.shop_id

        return { number: i + 1, ...item, shop_name }
      })
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

async function reportPo(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    const { data } = await api.post({
      path: 'reportpurchase',
      body: { ...body, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        delete item.purchase_id_pri
        if (item.purchase_status_id == 1) {
          item.user_fullname = item.user_fullname
        } else if (item.purchase_status_id == 2) {
          item.user_fullname = item.user_fullname_confirm
        } else if (item.purchase_status_id == 3) {
          item.user_fullname = item.user_fullname_pay
        } else if (item.purchase_status_id == 4) {
          item.user_fullname = item.user_fullname_import
        } else {
          item.user_fullname = item.user_fullname_cancel
        }

        item.purchase_status_id = checkStatusPo(item.purchase_status_id)

        if (item.purchase_type_id == 1) {
          item.purchase_type_id = 'PO'
        } else {
          item.purchase_type_id = 'PO Online'
        }

        delete item.purchase_date_confirm
        delete item.purchase_date_pay
        delete item.purchase_expdate
        delete item.purchase_date_cancel
        delete item.user_fullname_confirm
        delete item.user_fullname_pay
        delete item.user_fullname_import
        delete item.user_fullname_cancel

        return { number: i + 1, ...item }
      })
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// รายงานการใช้คอร์ส
async function reportServe(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    let dataMain = await main_node.post({
      path: 'reportserve',
      body: { ...body, lang: i18n.locale },
    })
    const { data, price_sum_pay, drug_amount_total, drug_cost_total } = dataMain

    if (data) {
      arrFetchData = data.map((item, i) => ({
        number: i + 1,
        ...item,
        customer_birthdate: item.customer_birthdate ? sumdatetime(item.customer_birthdate, 'UpPer') : '',
        customer_tags: `${item.customer_tags ? item.customer_tags : ''}`,
        course_type_text: `${
          item.course_type == '1'
            ? `${i18n.t('limited_time')}`
            : item.course_type == '2'
            ? `${i18n.t('limited_day')}`
            : item.course_type == '3'
            ? `${i18n.t('limited_drug')}`
            : `-`
        }`,
        // serve_date: sumdatetime(item.serve_date),
        // order_create: item.order_create ? sumdatetime(item.order_create) : '-',
      }))
      arrFetchData.push({
        course_name: i18n.t('total'),
        course_price: price_sum_pay,
        drug_amount: drug_amount_total,
        drug_cost: drug_cost_total,
      })
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

async function reportServe2(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    const { data, price_sum_pay2 } = await main_node.post({
      path: 'reportServeOld',
      body: { ...body, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => ({
        number: i + 1,
        ...item,
        customer_birthdate: item.customer_birthdate ? sumdate(item.customer_birthdate, 'UpPer') : '',
        customer_tags: `${item.customer_tags ? item.customer_tags : ''}`,
      }))
      arrFetchData.push({
        cost_total: i18n.t('total'),
        net_profit: price_sum_pay2,
      })
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// สรุปความเคลื่อนไหวการใช้คอร์ส
async function reportServiceActivity(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload

     //let dataDetail = [] ;
    const { data, sum_total, sum_use, sum_wait, count, count_of_page, segment } = await main_node.post({
      path: 'reportserviceactivity',
      body: { ...body, lang: i18n.locale },
    })
    arrFetchData = data.map((item, i) => {
      const course_use_date =
        item.course_use_date !== 'ไม่มีการใช้งาน' ? sumdate(item.course_use_date) : item.course_use_date

      return {
        number: i + 1,
        ...item,
        customer_birthdate: sumdate(item.customer_birthdate, 'UpPer'),
        customer_tags: `${item.customer_tags ? item.customer_tags : ''}`,
        course_use_date,
      }
    })
    arrFetchData.push({
      amount_cost: `${i18n.t('total')}`,
      total_cost: sum_total,
      use_cost: sum_use,
      wait_cost: sum_wait,
    })

    return { data: arrFetchData, count, count_of_page, segment }
  } catch (err) {
    console.log(err)
  }
}

// รายงานตรวจสอบคอร์ส
async function courseReport(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload

    const { data } = await main_node.post({
      path: 'reportservice',
      body: { ...body, lang: i18n.locale },
    })
    arrFetchData = data.map((item, i) => {
      const customer_create = sumdate(item.customer_create)
      const use_date = moment(item.use_date).isValid() ? sumdate(item.use_date) : item.use_date

      return {
        number: i + 1,
        ...item,
        customer_create,
        use_date,
      }
    })

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// รายงานสรุปยอดคงเหลือคอร์ส
async function reportServiceSummary(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    let { datacourse, data, datadrug } = await main_node.post({
      path: 'reportservicesummary',
      body: { ...body, lang: i18n.locale },
    })

    if (data) {
      data = data.map((item, i) => {
        const serving_date = moment(item.serving_date).isValid() ? sumdate(item.serving_date) : item.serving_date
        const serving_end = moment(item.serving_end).isValid() ? sumdate(item.serving_end) : item.serving_end
        const course_name = `${item.course_name} (${item.course_id})`
        let course_unit
        if (item.course_unit == 'ครั้ง') {
          course_unit = i18n.t('times')
        } else {
          course_unit = i18n.t('set')
        }

        return {
          number: i + 1,
          ...item,
          serving_date,
          serving_end,
          course_name,
          course_unit,
        }
      })
    } else {
      data = []
    }

    if (datacourse) {
      arrFetchData = datacourse.map((item, i) => {
        const use_date = moment(item.use_date).isValid() ? sumdate(item.use_date) : item.use_date
        delete item.customer_id_pri

        return {
          number: i + 1,
          ...item,
          use_date,
        }
      })
    } else {
      datacourse = []
    }

    if (datadrug) {
      datadrug = datadrug.map((item, i) => {
        const servingdetail_book_modify = moment(item.servingdetail_book_modify).isValid()
          ? sumdate(item.servingdetail_book_modify)
          : item.servingdetail_book_modify
        const course_name = `${item.course_name} (${item.course_id})`
        const drug_name = `${item.drug_name} (${item.drug_id})`
        delete item.order_id_pri
        delete item.course_id
        delete item.drug_id

        return {
          number: i + 1,
          ...item,
          servingdetail_book_modify,
          course_name,
          drug_name,
        }
      })
    } else {
      datadrug = []
    }

    return { data, datacourse: arrFetchData, datadrug }
  } catch (err) {
    console.log(err)
  }
}

// สรุปยอดขายลูกค้า
async function reportCustomer(payload) {
  try {
    let arrFetchData = []
    const response = await main_node.post({
      path: 'reportcustomer',
      body: { ...payload, lang: i18n.locale },
    })

    const { data, count, count_of_page, segment } = response
    if (data) {
      arrFetchData = data.map((item, i) => {
        const customer_tags = `${item.customer_tags ? item.customer_tags : ''}`
        delete item.customer_id_pri
        delete item.customer_age

        return {
          number: i + 1,
          ...item,
          customer_tags,
        }
      })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData, pageData: { count, count_of_page, segment } }
  } catch (err) {
    console.log(err)
  }
}

// รายงานการนัดหมาย
async function reportAppoint(payload) {
  try {
    let arrFetchData = []
    const { data } = await main_node.post({
      path: 'reportappoint',
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        const appoint_date = moment(item.appoint_date).isValid() ? sumdate(item.appoint_date) : item.appoint_date

        return {
          number: i + 1,
          ...item,
          appoint_date,
        }
      })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

//  สรุปค่าใช้จ่าย
async function reportPay(payload) {
  try {
    const { data, sum_total } = await main_node.post({
      path: 'reportpay',
      body: { ...payload, lang: i18n.locale },
    })

    return { data, sum_total }
  } catch (err) {
    console.log(err)
  }
}

//  สรุปยอดค่ามือแพทย์
async function reportDoctor(payload) {
  try {
    let arrFetchData = []
    const { data, datauser, price_sum_pay, doctorCostList } = await main_node.post({
      path: 'reportdoctor',
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        // const date = moment(item.date).isValid() ? sumdatetime(item.date) : item.date
        delete item.course_type_id

        return {
          number: i + 1,
          ...item,
        }
      })

      arrFetchData.push({ date: `${i18n.t('total')}`, cost: price_sum_pay })
      if (datauser) {
        datauser.forEach(item => {
          arrFetchData.push({ date: item.user_fullname, cost: item.total })
        })
      }
    } else {
      arrFetchData = []
    }
    data.push({ date: `${i18n.t('total')}`, cost: price_sum_pay })

    return { data: arrFetchData, datauser, dataShow: data, doctorCostList }
  } catch (err) {
    console.log(err)
  }
}

//  สรุปยอดค่ามือพนักงาน
async function reportUser(payload) {
  try {
    let arrFetchData = []
    const { data, datauser, price_sum_pay, userCostList } = await main_node.post({
      path: 'reportuser',
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        // const date = moment(item.date).isValid() ? sumdatetime(item.date) : item.date

        return {
          number: i + 1,
          ...item,
        }
      })
      arrFetchData.push({ date: `${i18n.t('total')}`, cost: price_sum_pay })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData, datauser: datauser, userCostList }
  } catch (err) {
    console.log(err)
  }
}

// รายงานค่าคอมมิชชั่น
async function reportCommis(payload, path) {
  try {
    let arrFetchData = []
    const {
      data,
      datauser: datateam,
      price_sum_pay,
    } = await main_node.post({
      path: path,
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        // const order_date = moment(item.order_date).isValid() ? sumdatetime(item.order_date) : item.order_date

        return {
          number: i + 1,
          ...item,
          // order_date,
        }
      })
      arrFetchData.push({
        customer_name: `${i18n.t('total')}`,
        amount: price_sum_pay,
      })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData, datateam }
  } catch (err) {
    console.log(err)
  }
}

async function reportCommisTotal(payload) {
  try {
    let arrFetchData = []
    const { data, datateam, price_sum_pay } = await main_node.post({
      path: 'reportcommistotal',
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        // const date = moment(item.date).isValid() ? sumdatetime(item.date) : item.date

        return {
          number: i + 1,
          code: item.serve_code ? item.serve_code : item.opd_code ? item.opd_code : '',
          ...item,
        }
      })
      arrFetchData.push({ date: `${i18n.t('total')}`, amount: price_sum_pay })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData, datateam }
  } catch (err) {
    console.log(err)
  }
}

// สรุปยอดชำระเงิน

async function reportinComeSummary(payload) {
  try {
    let arrFetchData = []
    const { data, shopbank, sum_price } = await main_node.post({
      path: 'reportincomesummary',
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        // const orderpay_modify = moment(item.orderpay_modify).isValid()
        //   ? sumdatetime(item.orderpay_modify)
        //   : item.orderpay_modify
        // const order_create = moment(item.order_create).isValid() ? sumdatetime(item.order_create) : item.order_create

        return {
          number: i + 1,
          ...item,
          detail: `${item.detail ? item.detail : ''}`,
        }
      })
      arrFetchData.push({ type: `${i18n.t('total')}`, price: sum_price })
      for (const item of shopbank) {
        if (+item.shop_bank_total.split(',').join('') > 0) {
          let m = 0
          arrFetchData.push({ type: item.shop_bank })
          for (const sub_item of item.shop_bank_detail) {
            if (sub_item.shop_bank_price != '0.00') {
              arrFetchData.push({
                type: sub_item.shop_bank_name,
                price: sub_item.shop_bank_price,
              })
            }
            if (m == item.shop_bank_detail.length - 1) {
              arrFetchData.push({
                type: `${i18n.t('total')}`,
                price: item.shop_bank_total,
              })
            }
            m++
          }
        }
      }
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// รายงานบัญชีลูกหนี้
async function reportOrderPay(payload) {
  try {
    let arrFetchData = []
    const { data, total_amount } = await main_node.post({
      path: 'reportorderpay',
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        // const orderpay_modify = moment(item.orderpay_modify).isValid()
        //   ? sumdatetime(item.orderpay_modify)
        //   : item.orderpay_modify

        // delete item.order_id_pri
        return {
          number: i + 1,
          ...item,
          // orderpay_modify,
        }
      })
      arrFetchData.push({
        orderpay_all: `${i18n.t('total')}`,
        orderpay_balance: total_amount,
      })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

async function reportWallet(payload) {
  try {
    let arrFetchData = []
    const { data, total_amount, shop_bank } = await main_node.post({
      path: 'reportwallet',
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        const date = moment(item.date).isValid() ? sumdate(item.date) : item.date
        delete item.order_id_pri

        return {
          number: i + 1,
          ...item,
          date,
        }
      })
      if (shop_bank) {
        for (const item of shop_bank) {
          arrFetchData.push({ date: item.shop_bank_name, amount: item.sum })
        }
      }
      arrFetchData.push({ date: `${i18n.t('total')}`, amount: total_amount })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

async function reportCheck(payload) {
  try {
    let arrFetchData = []
    const { data, fee_sum, price_sum, discount_sum, price_sum_pay } = await main_node.post({
      path: 'reportcheck',
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        const opd_date = moment(item.opd_date).isValid() ? sumdatetime(item.opd_date) : item.opd_date

        return {
          number: i + 1,
          ...item,
          opd_date,
          customer_fullname: `${item.customer_fullname}`,
        }
      })
      arrFetchData.push({
        opdchecking_name: `${i18n.t('total')}`,
        opdchecking_fee: fee_sum,
        opdchecking_price: price_sum,
        opdchecking_discount: discount_sum,
        opdchecking_cost: price_sum_pay,
      })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

async function reportLab(payload) {
  try {
    let arrFetchData = []
    const { data } = await main_node.post({
      path: 'reportlab',
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        const opd_date = moment(item.opd_date).isValid() ? sumdatetime(item.opd_date) : item.opd_date
        const opd_date_old = moment(item.opd_date_old).isValid() ? sumdatetime(item.opd_date_old) : item.opd_date_old

        return {
          number: i + 1,
          ...item,
          opd_date,
          opd_date_old,
        }
      })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

async function reportPsychotropic2(payload) {
  try {
    const response = await api.post({
      path: 'reportpsychotropic2',
      body: payload,
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

async function reportPsychotropic3(payload) {
  try {
    const response = await api.post({
      path: 'reportpsychotropic3',
      body: { ...payload, lang: i18n.locale },
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

async function reportPsychotropic4(payload) {
  try {
    const response = await api.post({
      path: 'reportpsychotropic4',
      body: { ...payload, lang: i18n.locale },
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

async function reportPsychotropic5(payload) {
  try {
    const response = await api.post({
      path: 'reportpsychotropic5',
      body: { ...payload, lang: i18n.locale },
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

async function reportCommission(payload) {
  try {
    const response = await main_node.post({
      path: 'reportcommission',
      body: { ...payload, lang: i18n.locale },
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

async function removeComma(data) {
  try {
    const response = data.map(item => {
      for (const [key, value] of Object.entries(item)) {
        if (
          `${item[key]}`.includes('.') &&
          /([0-9])\.[0-9][0-9]/.test(`${item[key]}`) &&
          !/([ก-๙A-Za-z])/.test(`${item[key]}`)
        ) {
          item[key] = parseFloat(`${item[key]}`.split(',').join(''))
        }
      }

      return item
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

async function htmlToExcel(payload) {
  try {
    let { html, filename } = payload
    const excelFileName = 'excel_table_data'
    const TableDataType = 'application/vnd.ms-excel'
    const htmlTable = html.replace(/ /g, '%20')
    filename = filename ? `${filename}.xls` : `${excelFileName}.xls`
    const excelFileURL = document.createElement('a')
    document.body.appendChild(excelFileURL)
    if (navigator.msSaveOrOpenBlob) {
      const blob = new Blob(['\ufeff', htmlTable], {
        type: TableDataType,
      })
      navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      excelFileURL.href = `data:${TableDataType}, ${htmlTable}`
      excelFileURL.download = filename
      excelFileURL.click()
    }
  } catch (err) {
    console.log(err)
  }
}

async function reportSummarYonline(payload) {
  try {
    const response = await api.post({
      path: 'reportsummaryonline',
      body: { ...payload, lang: i18n.locale },
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

async function reportSummarySite(payload) {
  try {
    const response = await api.post({
      path: 'reportsummarysite',
      body: { ...payload, lang: i18n.locale },
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

async function reportReview(payload) {
  try {
    const response = await api.post({
      path: 'reportreview',
      body: { ...payload, lang: i18n.locale },
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

async function reportAssessment(payload) {
  try {
    const response = await api.post({
      path: 'reportassessment',
      body: { ...payload, lang: i18n.locale },
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

async function reportmember(payload) {
  try {
    const response = await main_node.post({
      path: 'reportmember',
      body: { ...payload, lang: i18n.locale },
    })

    response.data.push({
      user_fullname: `${i18n.t('total')}`,
      total: response.total_price,
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

// reportDepositSummary
async function reportDepositSummary(payload) {
  try {
    let arrFetchData = []
    const { data } = await main_node.post({
      path: 'reportincomesummary/deposit',
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        // const orderpay_modify = moment(item.orderpay_modify).isValid()
        //   ? sumdatetime(item.orderpay_modify)
        //   : item.orderpay_modify
        // const order_create = moment(item.order_create).isValid() ? sumdatetime(item.order_create) : item.order_create

        return {
          number: i + 1,
          ...item,
          detail: `${item.detail ? item.detail : ''}`,
        }
      })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// reportincomesummary
async function reportinvoice(payload) {
  try {
    let arrFetchData = []
    const { data } = await api.post({
      path: 'reportinvoice',
      body: { ...payload, lang: i18n.locale },
    })
    if (data) {
      arrFetchData = data.map((item, i) => {
        // item.order_create = sumdatetime(item.order_create)
        delete item.order_deposit_amount
        delete item.order_totalpay
        return {
          number: i + 1,
          ...item,
        }
      })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

export {
  reportinvoice,
  reportProfit,
  reportSummary,
  reportDrug,
  reportcourse,
  reportCheckAnalysis,
  reportSalesAnalysis,
  reportDrugAnalysis,
  reportDisPensing,
  reportLotDrug,
  reportDrugBalance,
  reportMovementDrug,
  reportDrugLowest,
  reportExpired,
  reportTransfer,
  reportMovementDrug2,
  reportServe,
  reportServiceActivity,
  courseReport,
  reportServiceSummary,
  reportCustomer,
  reportAppoint,
  reportPay,
  reportDoctor,
  reportUser,
  reportCommis,
  reportCommisTotal,
  reportinComeSummary,
  reportOrderPay,
  reportWallet,
  reportCheck,
  reportLab,
  reportPsychotropic2,
  reportPsychotropic3,
  reportPsychotropic4,
  reportCommission,
  reportServe2,
  reportPsychotropic5,
  removeComma,
  htmlToExcel,
  reportDepositSummary,
  reportSummarYonline,
  reportReview,
  reportAssessment,
  reportSummarySite,
  reportmember,
  reportPo,
  reportSummaryNew,
}
