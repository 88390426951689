var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_c('Comeback'),_vm._v(" "+_vm._s(_vm.$t('Expense Summary'))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"d-none d-md-block",attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.exportLoading},on:{"click":_vm.exportExcel}},[_vm._v(" "+_vm._s(_vm.$t('excel'))+" ")]),_c('v-btn',{staticClass:"d-block d-md-none",attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.exportLoading,"icon":"","fab":"","outlined":""},on:{"click":_vm.exportExcel}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFileExcelOutline))])],1)],1),_c('DateFilters',{on:{"onSendDate":_vm.addPayload}}),_c('v-row',{staticClass:"px-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.ExpanseStatusList,"label":_vm.$t('status'),"dense":"","outlined":"","item-text":_vm.$i18n.locale,"item-value":"value"},on:{"change":function($event){return _vm.addPayload(_vm.payload.start, _vm.payload.end)}},model:{value:(_vm.ExpanseStatusSelect),callback:function ($$v) {_vm.ExpanseStatusSelect=$$v},expression:"ExpanseStatusSelect"}})],1)],1),_c('span',{staticClass:"font-weight-bold tetx-lg pl-2"},[_vm._v(" "+_vm._s(_vm.$t("summary_of_drug_costs"))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.columns1,"items":_vm.drug_costs.list,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.type == _vm.$t('total')?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.type == _vm.$t('total')?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.amount)+" ")])]}}],null,true)}),_c('span',{staticClass:"font-weight-bold tetx-lg pl-2"},[_vm._v(" "+_vm._s(_vm.$t("summary_of_cost_of_equipment_used"))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.columns1,"items":_vm.cost_of_equipment_used.list,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.type == _vm.$t('total')?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.type == _vm.$t('total')?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.amount)+" ")])]}}],null,true)}),_c('span',{staticClass:"font-weight-bold tetx-lg pl-2"},[_vm._v(" "+_vm._s(_vm.$t("summary_of_examination_fees"))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.columns2,"items":_vm.examination_fees.list,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.name == _vm.$t('total')?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.name == _vm.$t('total')?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.amount)+" ")])]}}],null,true)}),_c('span',{staticClass:"font-weight-bold tetx-lg pl-2"},[_vm._v(" "+_vm._s(_vm.$t("summary_the_cost_of_a_doctor"))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.columns2,"items":_vm.cost_of_a_doctor.list,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.name == _vm.$t('total')?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.name == _vm.$t('total')?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.amount)+" ")])]}}],null,true)}),_c('span',{staticClass:"font-weight-bold tetx-lg pl-2"},[_vm._v(" "+_vm._s(_vm.$t("summary_of_staff_costs"))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.columns2,"items":_vm.staff_costs.list,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.name == _vm.$t('total')?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.name == _vm.$t('total')?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.amount)+" ")])]}}],null,true)}),_c('span',{staticClass:"font-weight-bold tetx-lg pl-2"},[_vm._v(" "+_vm._s(_vm.$t("summary_of_general_expenses"))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.columns3,"items":_vm.general_expenses,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.name == _vm.$t('total')||item.name == _vm.$t('total_amount')||item.name == ((_vm.$t('total')) + " " + (_vm.$t('expenditure')))?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.name == _vm.$t('total')||item.name == _vm.$t('total_amount')||item.name == ((_vm.$t('total')) + " " + (_vm.$t('expenditure')))?'font-weight-bold primary--text':''},[_vm._v(" "+_vm._s(item.amount)+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }